import React from 'react'

import './Navigation.scss'

const Navigation = ({ current }) => (
    <nav className="navigation">
        <a className="year" href="/year">{current.format('YYYY')}</a>
        <a className="month" href="/month">{current.format('MMMM')}</a>
        <a className="day active" href="/day">{current.format('dddd D.')}</a>
    </nav>
)

export default Navigation