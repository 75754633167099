import React from 'react'

import './Day.scss'

const Day = props => {
    const Tag = props.as || 'div'
    return (
        <Tag className="day">
            <div className="number">{props.date.format('D')}</div>
            <div className="name">{props.date.format('dddd')}</div>
        </Tag>
    )
}

export default Day