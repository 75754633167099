import React from 'react'
import moment from 'moment'

import Day from './entity/Day'
import DayLayout from './layout/DayLayout'
import Navigation from './layout/Navigation'

import './App.scss'

const App = () => {
    const current = moment()

    const days = []
    for (let i = 0; i < 5; i++) {
        days.push(<DayLayout active={i === 0} key={i} />)
    }

    return (
        <div className="momentary-app">
            <React.StrictMode>
                <header>
                    <Day as="h1" date={current} />
                </header>
                <Navigation current={current} />
                <main>
                    {days}
                </main>
            </React.StrictMode>
        </div>
    )
}

export default App
