import React from 'react'

import './DayLayout.scss'

const DayLayout = ({ active }) => {
    const times = []
    for (let i = 0; i < 24; i++) {
        times.push(<div className="time" key={i}>{i}</div>)
    }

    return (
        <div className={'day-layout' + (active ? ' active' : '')}>
            {times}
            <div className="day-content"></div>
        </div>
    )
}

export default DayLayout